import React from 'react'

import Layout from '../../components/english_layout'
import SEO from '../../components/seo'
import Banner from '../../components/banner'
import Foot from '../../components/footer'

import poly_logo from '../../images/logo_poly_en.png'
import step_logo from '../../images/logo_step.webp'
import gigl_logo from '../../images/logo_gigl.png'
import bnc_logo from '../../images/logo_bnc_en.png'
import imc2_logo from '../../images/logo_imc2_en.png'
import logo_polyhx from '../../images/logo_polyhx.png'
import logo_cybereco from '../../images/logo_cybereco.png'
import logo_advens from '../../images/logo_advens.png'

import '../../styles/sponsers.sass'

const SponsersPage = () => {
    return (
        <Layout languageSwitchLink="/partners">
            <SEO title="Partenaires" />
            <Banner class_suffix="deep"/>

            <div className="presentation">
                <p>
                    Our partners allows us to carry out all our projects, take part in competitions and develop our club, so we thank them a lot!
                <br/>
                <br/>
                <b> Are you interested in supporting us and contributing to the development of our club? Contact us using <a href={'https://forms.gle/Fok7EFvNSDLVxLbG9'}>this form</a> or on <a href={'https://ca.linkedin.com/company/polycyber'}>LinkedIn</a>.</b>
                </p>

            </div>


            {/* Industrial partners */}
            <h1 className="title">Industrial partners</h1>
            
            <div className="sponsors-container">

                <div className="sponsors-logos">
                    <a href="https://cybereco.ca/">
                        <img src={logo_cybereco} />
                    </a>
                    <h4 className="partner">Cybereco</h4>
                    <p className="text">Founded in 2018, Cybereco brings together an increasing number of members with the common goal of accelerating the development of a world-class workforce and effective technological solutions for a prosperous and secure economy. 
                    <br/>Every year we organize a CTF competition with them!
                    </p>

                </div>

                <div className="sponsors-logos">
                    <a href="https://www.bnc.ca/">
                        <img src={bnc_logo} />
                    </a>
                    <h4 className="partner">National Bank</h4>
                    <p className="text"> A partner for many years, the National Bank is one of Canada's top employers, offering a dynamic environment. 
                    <br/>And cybersecurity in a bank is essential! That's why the NBC is recruiting extensively in the cyber field, from pentesting to cyber defense and governance.</p>
                </div>
                
            </div>

            <div className="sponsors-container">
                <div className="sponsors-logos">
                    <a href="http://www.advens.fr/">
                        <img src={logo_advens} />
                    </a>
                    <h4 className="partner">Advens</h4>
                    <p className="text"> Advens is a French leader, independent and sovereign in the field of cybersecurity. It is present throughout France, as well as in Quebec and Europe.
                    <br/>Its mission is to protect public and private organizations 24 hours a day, 365 days a year. 
                    <br/>This mission guides and drives Advens daily. But that’s not all. Its financial performance allows it to support and accelerate impactful projects through the “Advens for People And Planet” endowment fund.
                    <br/>As you can see, more than just cybersecurity experts, Advens is looking for committed and passionate collaborators.
                    </p>
                </div>
            </div>
            
            {/* Academic partners */}
            <h1 className="title">Academic partners</h1>
            <div className="sponsors-container">

                <div className="sponsors-logos">
                    <a href="https://www.polymtl.ca/">
                        <img src={poly_logo}/>
                    </a>
                    <h4 className="partner">Polytechnique Montréal</h4>
                    <p className="text"> Polytechnique Montréal is one of our main partners, and we thank them warmly!
                    <br/>It is thanks to their support that many of our projects are possible, and they bring us all together.
                    </p>
                    <p className="text">Internationally recognized as one of Canada's leading engineering schools, the school offers a wide choice of programs that enable our members to grow and learn. A true model, Polytechnique offers high-level teaching and research activities that address the major issues facing our society, influencing its environment intellectually, economically and socially. </p>
                    <p className="text">If cybersecurity interests you and you're looking for a recognized diploma, Polytechnique offers:
                        <ul>
                            <li>certificates and microprograms in cybersecurity</li>
                            <li>specializations in the computer engineering bachelor's program</li>
                            <li>a cumulative bachelor's degree in cybersecurity</li>
                            <li>master's degrees (professional or research) in cybersecurity</li>
                            <li>and doctorates, if research is your passion.</li>
                        </ul>
                    </p>

                </div>
                
                <div className="sponsors-logos">
                    <a href="https://i-mc2.ca/">
                        <img src={imc2_logo}/>
                    </a>
                    <h4 className="partner">Multidisciplinary Institute for Cybersecurity and Cyber Resilience </h4>
                    <p className="text"> The creation of IMC2 is based on an initial structuring partnership between Polytechnique Montréal, initiator of the project, Université de Montréal and HEC Montréal. </p>
                    <p className="text">It brings together all the resources of Montréal campus to carry out ambitious, innovative and multidisciplinary projects. With numerous professors and researchers involved in cybersecurity, as well as several training courses in the field, Montréal Campus is now a major Canadian player in this sector. Right from the start, the pooling of cyber expertise in technology, human factors, and management places IMC2 as a central actor in the ecosystem.</p>
                </div>
            </div>

            <div className="sponsors-container">
                <div className="sponsors-logos">
                    <a href="https://www.polymtl.ca/gigl/">
                        <img src={gigl_logo}/>
                    </a>
                    <h4 className="partner">GIGL - Department of Computer Engineering and Software Engineering</h4>
                    <p className="text">The GIGL is the Department of Computer Engineering and Software Engineering at Polytechnique Montréal, responsible for overseeing all computer and software programs at the school. They are in charge of all courses, specializations, and programs in cybersecurity. The GIGL provides us with significant support, and we take pride in representing them in our projects and competitions, thanks to the opportunities they enable for us. They also serve as our primary connection to the research institutes at Polytechnique, expanding our range of possibilities.</p>
                </div>


            </div>

            {/*Autres partenaires */}
            <h2 className="title">Student partners</h2>
            <div className="sponsors-container">

                <div className="sponsors-particuliers">
                    <a href="https://step.polymtl.ca/">
                        <img src={step_logo}/>
                    </a>
                    <h4 className="partner">Polytechnique Student Telematics Service (STEP)</h4>
                    <p className="text">We also thank STEP, the Polytechnique committee that helps us maintain our IT infrastructure!</p>
                </div>

                <div className="sponsors-particuliers">
                    <a href="https://polyhx.io/">
                        <img src={logo_polyhx}/>
                    </a>
                    <h4 className="partner">PolyHx</h4>
                    <p className="text">And finally, PolyHx, the technical computer science society at Polytechnique Montréal, of which we are a part!</p>
                </div>
                
            </div>

             {/*
            <h3>Patenaires du 24h@CTF</h3>
            <div className="sponsors-container">
                <div className="sponsors-logos">
                    <a href="https://www.cybereco.ca/">
                        <img src={poly_logo} className="eco-logo" />
                    </a>
                </div>
                <div className="sponsors-logos">
                    <a href="https://www.desjardins.com/">
                        <img src={poly_logo} className="desj-logo" />
                    </a>
                </div>
                <div className="sponsors-logos">
                    <a href="https://www.matlem.com/">
                        <img src={poly_logo} className="maltem-logo" />
                    </a>
                </div>
                <div className="sponsors-logos">
                    <a href="https://www.yogosha.com/">
                        <img src={poly_logo} className="bnc-logo" />
                    </a>
                </div>
            </div>
            */} 
            <Foot></Foot>
        </Layout>
    )
}
export default SponsersPage